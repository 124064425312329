<template>
  <div class="company-market-data">
    <div class="comp-md-header">Holdings</div>
    <f7-block>
      <div class="data-table data-table__dense">
        <table v-if="overviewData.holdings && overviewData.holdings.length > 0">
          <thead>
            <tr>
              <th />
              <th class="number-cell">MV($MM)</th>
              <th class="number-cell">#Shares</th>
              <th class="number-cell">%PORT</th>
              <th class="number-cell">%BMK</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(h, index) in sortedHoldings" :key="index">
              <td class="label-cell">{{ h.strategyShortName }}</td>
              <td class="number-cell">{{ toMM(h.marketValue) }}</td>
              <td class="number-cell">{{ toNumLocale(h.shareQuantity) }}</td>
              <td class="number-cell">{{ toPercentage(h.portfolioPercentage) }}</td>
              <td class="number-cell">{{ h.benchmarkWeight | number(2) }}</td>
            </tr>
          </tbody>
        </table>
        <p class="no-data-for-table" v-else>
          <i class="f7-icons info-icon">info_fill</i>Currently no strategy holds this security.
        </p>
      </div>
    </f7-block>

    <div class="comp-md-header">Quant Model</div>
    <f7-block>
      <div class="data-table data-table__dense">
        <table v-if="hasQuantScores">
          <thead>
            <tr>
              <th />
              <th class="number-cell">CURRENT</th>
              <th class="number-cell">1MO</th>
              <th class="number-cell">3MO</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="{ categoryName, scores, subcategories } in overviewData.quantScores">
              <tr class="level-1" :key="categoryName">
                <td class="label-cell">{{ categoryName }}</td>
                <td
                  class="numeric-cell"
                  v-for="(value, index) in scores"
                  :key="categoryName + '_' + index"
                >
                  {{ value }}
                </td>
              </tr>
              <tr
                class="level-2"
                v-for="{ categoryName, scores } in subcategories"
                :key="categoryName"
              >
                <td class="label-cell">{{ categoryName }}</td>
                <td
                  class="numeric-cell"
                  v-for="(value, index2) in scores"
                  :key="categoryName + '_' + index2"
                >
                  {{ value }}
                </td>
              </tr>
            </template>
          </tbody>
        </table>
        <p class="no-data-for-table" v-else>
          <i class="f7-icons info-icon">info_fill</i>Quant score is not available.
        </p>
      </div>
    </f7-block>

    <div class="comp-md-header">
      ESG Overview
      <span v-if="overviewData && overviewData.esgData" class="header-extra-info pull-right"
        >MSCI Rating:
        {{
          overviewData && overviewData.esgData && overviewData.esgData.esgScore
            ? overviewData.esgData.esgScore
            : '-'
        }}</span
      >
    </div>
    <f7-block>
      <div class="data-table data-table__dense">
        <table v-if="hasESGData">
          <thead>
            <tr>
              <th />
              <th class="number-cell">WB RATING</th>
              <th class="number-cell">MSCI RATING</th>
              <th class="number-cell">MSCI WT(%)</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(pillar, pillarName, index) in allESGData" :key="index">
              <td class="label-cell">{{ pillarName }}</td>
              <td class="number-cell" style="text-align:right;padding-right:35px;">
                {{ pillar.wb || '-' }}
              </td>
              <td class="number-cell" style="text-align:center;" v-if="pillar.msci">
                {{ pillar.msci.score || '-' }}
              </td>
              <td class="number-cell" style="text-align:center;" v-if="!pillar.msci">-</td>
              <td class="number-cell" style="text-align:center;" v-if="pillar.msci">
                {{ pillar.msci.weight || '-' }}
              </td>
              <td class="number-cell" style="text-align:center;" v-if="!pillar.msci">-</td>
            </tr>
            <tr
              v-if="
                companyInfo &&
                  companyInfo.wbEsgRating &&
                  companyInfo.wbEsgRating.isESGBIC &&
                  viewType &&
                  viewType.isGlobal
              "
            >
              <td>&nbsp;</td>
              <td style="text-align:right;padding-right:26px;padding-top:3px;">
                <img width="25" height="25" src="../../assets/esg.png" />
              </td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
            </tr>
            <tr v-if="companyInfo.esgEnabler" style="border-top:1px solid #dddddd;">
              <td colspan="4" style="padding-left:10px;">
                ESG Enabler:
                <strong style="margin-left:15px;">{{
                  companyInfo.esgEnabler.esgEnablerDescription
                }}</strong>
              </td>
            </tr>
          </tbody>
        </table>
        <p class="no-data-for-table" v-else>
          <i class="f7-icons info-icon">info_fill</i>ESG data is not available.
        </p>
      </div>
    </f7-block>

    <div class="comp-md-header">
      Prices&nbsp;&nbsp;&nbsp;
      <span style="font-size:.8rem;color:#777;">({{ companyInfo.info.tradingCurrencyCode }})</span>
    </div>
    <f7-block>
      <price-chart-basic :issuer-id="issuerId" />
    </f7-block>

    <div class="comp-md-header">Key Financial Metrics</div>
    <f7-block>
      <div class="data-table data-table__dense">
        <table v-if="hasKeyFinancialMetrics">
          <thead>
            <tr>
              <th />
              <th class="number-cell">FY-2</th>
              <th class="number-cell">FY-1</th>
              <th class="number-cell stand-out">FYA</th>
              <th class="number-cell">FY1</th>
              <th class="number-cell">FY2</th>
            </tr>
          </thead>
          <tbody>
            <template
              v-for="({ categoryName, values, isPercentage, precision, subCategories },
              index) in overviewData.keyFinancialMetrics"
            >
              <tr class="level-1" :key="categoryName + '_' + index">
                <td class="label-cell">{{ categoryName }}</td>
                <td
                  class="numeric-cell"
                  :class="{ 'stand-out': index == 2 }"
                  v-for="(value, index) in values"
                  :key="index"
                  v-html="formatFinancialMetrics(value, isPercentage, precision)"
                />
              </tr>
              <tr
                class="level-2"
                v-for="({ categoryName, isPercentage, precision, values },
                subIndex) in subCategories"
                :key="categoryName + '_' + subIndex"
              >
                <td class="label-cell">{{ categoryName }}</td>
                <td
                  class="numeric-cell"
                  :class="{ 'stand-out': index == 2 }"
                  v-for="(value, index) in values"
                  :key="categoryName + '_' + index"
                  v-html="formatFinancialMetrics(value, isPercentage, precision)"
                />
              </tr>
            </template>
          </tbody>
        </table>
        <p class="no-data-for-table" v-else>
          <i class="f7-icons info-icon">info_fill</i>Key financial metrics data is not available.
        </p>
      </div>
    </f7-block>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'CompanyMarketData',
  components: { PriceChartBasic: () => import('../../components/PriceChartBasic.vue') },
  props: {
    issuerId: { type: [String, Number], required: true }
  },
  beforeMount() {
    this.$store.dispatch('companyPage/loadIssuerOverviewData', this.issuerId)
  },
  computed: {
    ...mapState('companyPage', ['companyInfo', 'companyOverviewData']),
    ...mapState('user', ['viewType']),
    overviewData() {
      return this.companyOverviewData
    },
    sortedHoldings() {
      if (!this.overviewData || !this.overviewData.holdings) {
        return []
      }
      return this.overviewData.holdings.slice(0).sort((a, b) => {
        if ((a.isPaper || b.isPaper) && a.isPaper !== b.isPaper) {
          return a.isPaper ? 1 : -1
        }
        return b.marketValue - a.marketValue
      })
    },
    hasQuantScores() {
      return this.overviewData.quantScores && this.overviewData.quantScores.length > 0
    },
    msciEsgCategories() {
      if (
        this.overviewData.esgData &&
        this.overviewData.esgData.categories &&
        this.overviewData.esgData.categories.length > 0
      ) {
        return this.overviewData.esgData.categories
      }
      return []
    },
    allESGData() {
      const data = {
        Environmental: {},
        Social: {},
        Governance: {}
      }

      if (this.msciEsgCategories) {
        this.msciEsgCategories.forEach((category) => {
          if (category.categoryName.toLowerCase().indexOf('environmental') > -1) {
            data.Environmental.msci = category
          }
          if (category.categoryName.toLowerCase().indexOf('social') > -1) {
            data.Social.msci = category
          }
          if (category.categoryName.toLowerCase().indexOf('governance') > -1) {
            data.Governance.msci = category
          }
        })
      }

      if (this.companyInfo && this.companyInfo.wbEsgRating) {
        for (let category in this.companyInfo.wbEsgRating) {
          if (category.toLowerCase().indexOf('environmental') > -1) {
            data.Environmental.wb = this.companyInfo.wbEsgRating[category]
          }
          if (category.toLowerCase().indexOf('social') > -1) {
            data.Social.wb = this.companyInfo.wbEsgRating[category]
          }
          if (category.toLowerCase().indexOf('governance') > -1) {
            data.Governance.wb = this.companyInfo.wbEsgRating[category]
          }
        }
      }

      return data
    },
    hasESGData() {
      return (
        (this.overviewData.esgData &&
          this.overviewData.esgData.categories &&
          this.overviewData.esgData.categories.length > 0) ||
        (this.companyInfo && this.companyInfo.wbEsgRating)
      )
    },
    hasKeyFinancialMetrics() {
      const metrics = this.overviewData.keyFinancialMetrics
      return metrics && metrics.length > 0
    }
  },
  methods: {
    toNumLocale(input, precision = 2) {
      const formatter = new Intl.NumberFormat('en-US', { maximumFractionDigits: precision })
      return formatter.format(input)
    },
    toPercentage(input, precison = 2, showPercentageMark = false) {
      if (typeof input !== 'number' || isNaN(input)) return 'NA'
      return (input * 100).toFixed(precison) + (showPercentageMark ? '%' : '')
    },
    toMM(input, precision = 2) {
      if (typeof input !== 'number' || isNaN(input)) return 'NA'
      return this.toNumLocale(input / 1000000, precision)
    },
    formatFinancialMetrics(value, isPercentage, precision = 1) {
      if (isNaN(Number(value))) {
        return '-'
      }
      const formatter = new Intl.NumberFormat('en-US', { maximumFractionDigits: precision })
      const formated = formatter.format(value)
      const tag = isPercentage ? 'em' : 'span'
      return `<${tag}>${formated}</${tag}>`
    }
  }
}
</script>

<style lang="scss">
.company-market-data {
  .comp-md-header {
    width: 100%;
    padding: 5px;
    box-sizing: border-box;
    background: #edf0ee;
    border-top: 1px solid #f5f5f5;
    border-bottom: 1px solid #f5f5f5;
    font-weight: 600;
  }
  .header-extra-info {
    color: #666;
    padding-left: 10px;
    margin-top: 2px;
    font-weight: normal;
    font-size: 0.8rem;
  }
  .block {
    margin: 0;
    padding: 0;
  }
  .no-data-for-table {
    color: #999;
    padding: 0 10px;
    .info-icon {
      margin-right: 6px;
      font-size: 14px;
    }
  }
  .data-table__dense {
    font-size: 13px;
    background: #fff;

    tr.level-1 td:first-of-type {
      font-weight: 500;
    }
    .stand-out {
      background: #f6f6f6;
    }
    tr.level-2 td:first-of-type {
      padding-left: 20px;
    }
    th,
    td {
      padding-left: 10px;
      padding-right: 10px;
      height: 28px;
    }
    td span {
      margin: 0;
    }
    .label-cell {
      width: 100px;
    }
    .number-cell {
      text-align: right;
    }
  }
}
</style>
